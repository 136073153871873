@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: gayathri;
    src: url(../public/Gayathri/Gayathri-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: gayathri;
    src: url(../public/Gayathri/Gayathri-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: gayathri;
    src: url(../public/Gayathri/Gayathri-Thin.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}