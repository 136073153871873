.map {
  font-family: Arial, Helvetica, sans-serif;
  width: 80%;
  height: 20rem;
  margin: 0 auto;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -o-border-radius: 20px;
  -khtml-border-radius: 20%;
  border-radius: 20px;
  overflow: hidden;
}

@media (max-width: 480px) {
    .map {
        width: 90%;
        height: 13rem;
        font-size: medium;
    }    

    .ol-control button{
        width: 0rem !important;
        height: 0rem !important;
        border-radius: 20% !important;
        /* background-color: #FDE6B7;   */
        /* filter: drop-shadow(0px 0px 50px #FDE6B7);  */
    }
}

.ol-control button{
  width: 3rem;
  height: 3.5rem;
  border-radius: 20% !important;
  /* background-color: #FDE6B7;   */
  /* filter: drop-shadow(0px 0px 50px #FDE6B7); */
  font-size: x-large;
  text-align: center;
  text-decoration: none;
  border: none;
  overflow: hidden;
}