@tailwind base;
@tailwind components;
@tailwind utilities;
/* TailWind does not work */

@import url('https://fonts.googleapis.com/css2?family=Gayathri&display=swap');

div{scroll-behavior:smooth}

html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
}

/* Debug */
/* * {
  background: #000 !important;
  color: #0f0 !important;
  outline: solid #f00 1px !important;
} */


@keyframes presentPage {
  0% {
    transform: scale(5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.axum-logo{
  animation: 1.2s ease-out 0s 1 presentPage;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  width: calc(47rem + (200 - 47) * ((100vw - 480px) / (1600 - 480)));
  transition: 0.7s all;
}
.axum-logo:hover{
  cursor: pointer;
  width: calc(28rem + (200 - 28) * ((100vw - 480px) / (1600 - 480)));
}

br {
  display: block; /* makes it have a width */
  content: ""; /* clears default height */
  margin-top: -5px; /* change this to whatever height you want it */
}

.Landing-page {
  background-image: url('./images/RR-BG.jpg');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  text-align: center;
  font-family: 'Gayathri', sans-serif;
}

@media (max-width: 500px) {
  .Landing-page {
    background-image: url('./images/RR-BG.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-position: 60% 0%; 
    background-size: cover;
    min-height: 100vh;
    text-align: center;
    font-family: 'Gayathri', sans-serif;
    font-size: 3rem;
  }
}

.text-scale-2xl {
  font-size: calc(3rem + (40 - 3) * ((100vw - 480px) / (1600 - 480)));
}

.text-scale-xl {
  font-size: calc(2.5rem + (30 - 2.5) * ((100vw - 480px) / (1600 - 480)));
}

.text-scale-md {
  font-size: calc(1.5rem + (10 - 1.5) * ((100vw - 480px) / (1600 - 480)));
}

.text-scale-sm {
  font-size: calc(1.2rem + (5 - 1.2) * ((100vw - 480px) / (1600 - 480)));
}

.treatment-page {
  background-color: #6DB3CB;
  background-size: cover;
  min-height: 90vh;
  text-align: center;
  font-family: 'Gayathri', sans-serif;
  font-size: 3rem;
}

.about {
  background-color: #6DB3CB;
  background-size: cover;
  min-height: 90vh;
  text-align: center;
  font-family: 'Gayathri', sans-serif;
  font-size: 3rem;
}


.Mobile{
  /* none */
}

@media (max-widt: 1200px) {
    .Mobile{
      display: grid;
      background-color: rgb(208, 188, 114);
      background-size: cover;
      min-height: 200vh;
      text-align: center;
      font-family: 'Gayathri', sans-serif;    
      font-size: 3rem;
  }
}

.info-text {
  font-family: 'Gayathri', sans-serif;
}

nav{
  position: fixed;
  top: 0;
  width: 100%;
  font-family: 'Gayathri', sans-serif;
  line-height: 80px;
  font-size: 2rem;
}
nav ul{
  float: right;
  margin-right: 30px;
}
nav ul li{
  list-style-type: none;
  display: inline-block;
  transition: 0.7s all;
}
nav ul li:hover{
  color: #fff;
  cursor: pointer;
  border-radius: 0px 0px 2rem 2rem;
  font-size: larger;
}
nav ul li a{
  text-decoration: none;
  color: #fff;
  text-align: center;
  padding: 30px;
}
.nav-crown{
  position: absolute;
  left: 0%;
  top: -10%;
  width: 8rem;
  transition: 0.7s all;
}
.nav-crown:hover{
  cursor: pointer;
  position: absolute;
  left: 0%;
  top: -10%;
  width: 9rem;
  color: #fff;
}

.nav-text:hover{
  color: #fff;
}
.nav-text-book:hover{
  color: #fff;  
}

@media (max-width: 850px){
  .nav-text{
    visibility: hidden;
  }
  nav ul li:hover{
    transition: none;
    background-color: rgba(208, 188, 114, 0);;
    cursor: default;
    border-radius: 0px 0px 2rem 2rem;
    font-size: larger;
  }
  nav{
    position: absolute;
    top: 0%;
    width: 100%;
    line-height: 80px;
    font-size: 2rem;
  }
  .nav-text-book{
    border-radius: 0px 0px 2rem 2rem;
    transition: 0.7s all;
    position: absolute;
    top: -20px;
    right: 0px;
  }
  .nav-text-book:hover{
    background-color:rgba(208, 188, 114, 0.8);
  }
  .nav-crown{
    display: none;
  }
}

.card{
  background-color: #FDE6B7;
  width: 21.875rem;
  height: 21.875rem;
  filter: drop-shadow(0px 0px 50px #FDE6B7);
  font-family: 'Gayathri', sans-serif;
  text-align: left;
}
@media (max-width: 480px) {
  .card{
    background-color: #FDE6B7;
    width: 20.5rem;
    height: 21rem;
    filter: drop-shadow(0px 0px 50px #FDE6B7);
    font-family: 'Gayathri', sans-serif;
  }
}

.Room{
  background-image: url('./images/runners.jpeg');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 50vh;
  text-align: center;
  font-family: 'Gayathri', sans-serif;
  font-size: 3rem;
}

.room{
  background-image: url('./images/runners.jpeg');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 50vh;
  text-align: center;
  font-family: 'Gayathri', sans-serif;
  font-size: 3rem;
}

.qr{
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20rem;
}

.profile{
  width: 20rem;
}

[class*='gg-']:hover {
  filter: invert(1) sepia(1);
}

.hover-gold:hover {
  filter: invert(1) sepia(1);
}

.hover-gold-svg:hover {
  filter: invert(0.3) sepia(1);
}


@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
	40% {
    transform: translateY(-30px);
  }
	60% {
    transform: translateY(-15px);
  }
}
.arrow {
  position: fixed;
  bottom: 0;
  left: 50%;
  margin-left:-20px;
  width: calc(30px + (40 - 30) * ((100vw - 480px) / (1600 - 480)));
  height: calc(30px + (40 - 30) * ((100vw - 480px) / (1600 - 480)));
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0yOTMuNzUxLDQ1NS44NjhjLTIwLjE4MSwyMC4xNzktNTMuMTY1LDE5LjkxMy03My42NzMtMC41OTVsMCwwYy0yMC41MDgtMjAuNTA4LTIwLjc3My01My40OTMtMC41OTQtNzMuNjcyICBsMTg5Ljk5OS0xOTBjMjAuMTc4LTIwLjE3OCw1My4xNjQtMTkuOTEzLDczLjY3MiwwLjU5NWwwLDBjMjAuNTA4LDIwLjUwOSwyMC43NzIsNTMuNDkyLDAuNTk1LDczLjY3MUwyOTMuNzUxLDQ1NS44Njh6Ii8+DQo8cGF0aCBmaWxsPSIjRkZGRkZGIiBkPSJNMjIwLjI0OSw0NTUuODY4YzIwLjE4LDIwLjE3OSw1My4xNjQsMTkuOTEzLDczLjY3Mi0wLjU5NWwwLDBjMjAuNTA5LTIwLjUwOCwyMC43NzQtNTMuNDkzLDAuNTk2LTczLjY3MiAgbC0xOTAtMTkwYy0yMC4xNzgtMjAuMTc4LTUzLjE2NC0xOS45MTMtNzMuNjcxLDAuNTk1bDAsMGMtMjAuNTA4LDIwLjUwOS0yMC43NzIsNTMuNDkyLTAuNTk1LDczLjY3MUwyMjAuMjQ5LDQ1NS44Njh6Ii8+DQo8L3N2Zz4=);
  background-size: contain;
}

.bounce {
  animation: bounce 2s infinite;
}
[class*='hover-zoom-']:hover{
  transition: 0.7s all;
}

.boka-bokadir{
  width: 10.9rem;
  transition: 0.7s all;
}
  
.boka-epassi{
  padding-left: 2rem;
  padding-right: 2rem;
  width: 12rem;
  transition: 0.7s all;
}

.hover-zoom-1:hover{
  width: 12rem;
}

.hover-zoom-2:hover{
  width: 13rem;
}

